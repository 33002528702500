import axios from 'axios'
import { message } from 'ant-design-vue'
import router from '@/router'

// axios.defaults.baseURL = 'http://pipe.njszy.com/api'
axios.defaults.baseURL = 'https://huaiangis.njszy.com/api'
//axios.defaults.baseURL = 'http://10.23.21.37:19000'

if (process.env.NODE_ENV === 'production') {
    axios.defaults.baseURL = window.location.protocol + '//' + window.location.host + '/api'
}

const instance = axios.create({
})

instance.interceptors.request.use(function (config) {

    config.headers = {
        token: window.localStorage.getItem('token'),
    }

    return config;
}, function (error) {
    return Promise.reject(error)
})

instance.interceptors.response.use(function (response) {

    const { data = {} } = response;

    if (response.status === 201) {
        message.success("操作成功")
    }
    if (data == null) {
        /**
         * 2023.4.13 张弘 
         * ipc_controller 接口没有返回值，增加了这行避免报错 */
        // console.log('NULL DATA')
        return {}
    }
    return data.data ? data.data : {}

}, function (error) {
    const { status, data } = error.response;
    message.error(data.message ? data.message : '异常错误')


    if (status === 401) {
        router.push('/login')
    }
    return Promise.reject(error)
})

export default instance;