export default {

    namespaced: true,

    state() {
        return {
            permission: [],
            menu: [],
        }
    },

    getters: {
        hasPermission: (state) => (permissionCode) => {

            for (var item of state.permission) {
                if (item.code === permissionCode) {
                    return true
                }
            }
            return false
            //return state.todos.find(todo => todo.id === id)
        }
    },

    mutations: {
        setPermission(state, payload) {
            state.permission = payload
        },
        setMenu(state, payload) {
            state.menu = payload
        }
    },

    actions: {

    }

}