<template>
  <span>
    <span v-if="iconType === 'text'" :style="{ color: dict.icon }">{{
      dict.name
    }}</span>
    <a-badge
      v-else-if="iconType === 'badge'"
      :color="dict.icon"
      :text="dict.name"
    />
  </span>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    dictType: {
      type: String,
      default: "",
    },
    dictValue: {
      type: String,
      default: "",
    },
    iconType: {
      type: String,
      default: "text",
    },
  },
  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    dict() {
      const list = this.findDataDict(this.dictType);

      const obj = list.find((item) => item.value === this.dictValue);
      if (obj) {
        return obj;
      } else {
        return {};
      }
    },
  },
};
</script>
