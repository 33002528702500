import { fetchList as fetchDataDict } from '@/api/setting/data-dict'

function buildDataDictTree(list, pid) {
    const tree = [];
    list.forEach((item) => {
        if (item.pid === pid) {
            let children = buildDataDictTree(list, item.id);

            let params = {
                ...item,
                key: item.id,
            }

            if (children && children.length > 0) {
                params.children = children;
            }

            tree.push(params);
        }
    });

    tree.sort((x, y) => {
        const x1 = parseInt(x.sort);
        const y1 = parseInt(y.sort)
        if (typeof x1 === 'number' && typeof y1 === 'number') {
            if (x1 < y1) {
                return -1
            } else if (x1 > y1) {
                return 1;
            } else {
                return 0
            }
        } else {
            return 0
        }
    })



    return tree;
}

export default {

    namespaced: true,

    state() {
        return {
            menu: [],
            dataDict: [], // 数据字典列表
            rawDataDict: [], // 数据字典原始数据
        }
    },

    getters: {

        findDataDict: state => value => {
            const res = state.dataDict.find(item => item.value === value)
            if (res && Array.isArray(res.children)) {
                return res.children
            } else {
                return []
            }
        },

        findSingleDataDict: (state, getters) => (value1, value2) => {
            let dataDictList = getters.findDataDict(value1)
            return dataDictList.find(item => item.name === value2)?.value || '';
        },

    },

    mutations: {
        setMenu(state, payload) {
            state.menu = payload;
        },

        setDataDict(state, payload) {
            const list = buildDataDictTree(payload.list, payload.pid)
            state.dataDict = list;
        },

        setRawDataDict(state, payload) {
            state.rawDataDict = payload;
        }
    },

    actions: {
        getDataDict({ commit }) {
            return new Promise((resolve, reject) => {
                fetchDataDict().then(res => {

                    if (Array.isArray(res)) {
                        const newList = res.map(item => {
                            if (!item.pid) {
                                item.pid = null;
                            }
                            return item;
                        });

                        commit('setDataDict', {
                            list: newList,
                            pid: null,
                        })
                        commit('setRawDataDict', newList)
                    }
                    resolve()
                }).catch(() => {
                    reject()
                })
            })
        }
    }


}

