<template>
  <div class="card">
    <div class="header">
      <div class="title">{{ title }}</div>
    </div>
    <div class="body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
  },
};
</script>

<style lang="less" scoped>
.card {
  margin: 0 4vw;
  background-color: #000;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  .header {
    background-color: var(--theme-color);
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  .body {
    padding: 12px;
  }
}
</style>