import request from "@/api/request";

export default {

    namespaced: true,

    state() {
        return {
            facilityList: []
        }
    },

    mutations: {
        setFacilityList(state, payload) {
            state.facilityList = payload;
        },
    },

    getters: {
        facilityStatusList(state) {
            return state.facilityList.map((item) => item.type)
                .filter((item, index, self) => self.indexOf(item) === index)
                .map((type) => {
                    const result = state.facilityList.filter((item) => item.type === type);
                    return {
                        type,
                        normal: result.filter((item) => item.status === 1).length,
                        notNormal: result.filter((item) => item.status === 0).length,
                    };
                });
        },
        facilityStatusList_NotHaveRiverVersion(state) {
            return state.facilityList.map((item) => item.type)
                .filter((item, index, self) => self.indexOf(item) === index)
                .map((type) => {
                    const result = state.facilityList.filter((item) => item.type === type && item.subType.includes("river") === false);
                    return {
                        type,
                        normal: result.filter((item) => item.status === 1).length,
                        notNormal: result.filter((item) => item.status === 0).length,
                    };
                });
        },
        facilityStatusList_RiverVersion(state) {
            return state.facilityList.map((item) => item.type)
                .filter((item, index, self) => self.indexOf(item) === index)
                .map((type) => {
                    const result = state.facilityList.filter((item) => item.type === type && item.subType.includes("river"));
                    return {
                        type,
                        normal: result.filter((item) => item.status === 1).length,
                        notNormal: result.filter((item) => item.status === 0).length,
                    };
                });
        }
    },

    actions: {
        getFacilityList({ commit }) {
            request({
                url: "/model-analysis/pipe/facility/all",
            }).then((res) => {
                if (Array.isArray(res)) {
                    commit('setFacilityList', res)
                }
            });
        }
    }
}