export default {

    namespaced: true,

    state() {
        return {
            detail: {}, // 弹窗内容对象
            category: '', // 污水/雨水
            type: '', // 管网/检查井

            selectedWell: [],

            connectDetail: {}, // 连通性结果

            clickedMarkers: [], // 用户已点击的管线上的经纬度列表

            sewagePipeRawList: [], // 污水管网原始数据
            rainPipeRawList: [], // 雨水管网原始数据

            sewageWellRawList: [], // 污水检查井原始数据
            rainWellRawList: [], // 污水检查井原始数据

            selectedLnglat: [], // 用户在地图上选择的经纬度数组
            selectedLnglatLine: null, // 用户在地图上选择的经纬度数组连接成的线段

            controlType: '', // 用户选择操作的类型
        }
    },

    mutations: {
        setDetail(state, payload) {
            state.detail = payload;
        },
        setCategory(state, payload) {
            state.category = payload;
        },
        setType(state, payload) {
            state.type = payload;
        },
        setSelectedWell(state, payload) {
            state.selectedWell = payload;
        },
        setConnectDetail(state, payload) {
            state.connectDetail = payload;
        },


        setClickedMarkers(state, payload) {
            state.clickedMarkers = payload
        },

        setSewagePipeRawList(state, payload) {
            state.sewagePipeRawList = payload
        },
        setRainPipeRawList(state, payload) {
            state.rainPipeRawList = payload
        },

        setSewageWellRawList(state, payload) {
            state.sewageWellRawList = payload
        },
        setRainWellRawList(state, payload) {
            state.rainWellRawList = payload
        },

        setSelectedLnglat(state, payload) {
            state.selectedLnglat = payload;
        },
        setSelectedLnglatLine(state, payload) {
            state.selectedLnglatLine = payload;
        },

        setControlType(state, payload) {
            state.controlType = payload;
        }

    },

    actions: {

    }
}