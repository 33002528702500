import request from "@/api/request";
import { fetchRainPipe } from "@/api/rain";
import { fetchSewagePipe } from "@/api/sewage";
import { fetchRainDrainLocs } from "@/api/assets/outlet";

import { fetchWell } from "@/api/assets/sewage";
import { fetchWell as fetchRainWell } from "@/api/assets/rain";

export default {
  namespaced: true,

  state() {
    return {
      view: "管网",

      facilityList: [],
      sewagePipeList: [],
      rainPipeList: [],
      rainOutletList: [],
      sewageWellList: [],
      rainWellList: [],

      selectedLnglat: {},
      selectedWell: {},
      selectedPipe: {},
    };
  },

  mutations: {
    setView(state, payload) {
      state.view = payload
    },
    setFacilityList(state, payload) {
      state.facilityList = payload;
    },
    setSewagePipeList(state, payload) {
      state.sewagePipeList = payload;
    },
    setRainPipeList(state, payload) {
      state.rainPipeList = payload;
    },
    setRainOutletList(state, payload) {
      state.rainOutletList = payload;
    },
    setSewageWellList(state, payload) {
      state.sewageWellList = payload;
    },
    setRainWellList(state, payload) {
      state.rainWellList = payload;
    },
    setSelectedLnglat(state, payload) {
      state.selectedLnglat = payload
    },
    setSelectedWell(state, payload) {
      state.selectedWell = payload;
    },
    setSelectedPipe(state, payload) {
      state.selectedPipe = payload;
    },
  },
  actions: {
    getFacilityList({ commit }) {
      return request({
        url: "/model-analysis/pipe/facility/all",
      }).then((res) => {
        if (Array.isArray(res)) {
          commit("setFacilityList", res);
        }
      });
    },

    getSewagePipeList({ commit }) {
         fetchSewagePipe({}).then((res) => {
          if (res && Array.isArray(res.locs)) {
            commit('setSewagePipeList', res.locs)
          }
        });
    },

    getRainPipeList({ commit }) {
         fetchRainPipe({}).then((res) => {
          if (res && Array.isArray(res.locs)) {
            commit('setRainPipeList', res.locs)
          }
        });
    },

    getRainOutletList({ commit }) {
         fetchRainDrainLocs({}).then((res) => {
          if (res && Array.isArray(res.locs)) {
            commit('setRainOutletList', res.locs.map(item => {
              return {
                id: item[0],
                type: item[1],
                category: "rain",
                name: '雨水排口',
                x: parseFloat( item[3]),
                y: parseFloat( item[4]),
              }
            }))
          }
        });
    },



    getSewageWellList({ commit }) {
      // 2024-08-23 by zhanghong
      // location接口中返回数据增加了code项，代表编号
         fetchWell({}).then((res) => {
          if (res && Array.isArray(res.locs)) {
            commit('setSewageWellList', res.locs.map(item => {
             return {
              id: item[0],
              category: 'sewage',
              type: item[1],
              name: '污水检查井',
              x: parseFloat( item[3]),
              y: parseFloat( item[4]),
              code: item[5]
            };
            }))
          }
        });
    },

    getRainWellList({ commit }) {
         fetchRainWell({}).then((res) => {
          if (res && Array.isArray(res.locs)) {
            commit('setRainWellList', res.locs.map(item => {
            return {
              id: item[0],
                      category: 'rain',
              type: item[1],
              name: '雨水检查井',
              x: parseFloat( item[3]),
              y: parseFloat( item[4]),
              code: item[5]
            };
            }))
          }
        });
    },

  },
};
