<template>
  <span>{{ tweened.toFixed(0) }}</span>
</template>

<script >
import gsap from "gsap";
export default {
  props: ["num"],
  data() {
    return {
      tweened: 0,
    };
  },
  mounted() {
    const num = this.num;
    gsap.to(this, { duration: 1, tweened: Number(num) || 0 });

    this.timer = setInterval(() => {
      gsap.to(this, { duration: 1, tweened: Number(0) || 0 });
      setTimeout(() => {
        gsap.to(this, { duration: 1, tweened: Number(num) || 0 });
      }, 1000);
    }, 10000);
  },
  unmounted() {
    clearInterval(this.timer);
  },
};
</script>