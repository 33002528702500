import Vue from 'vue'
import Vuex from 'vuex'

import setting from './modules/setting'
import auth from './modules/auth'

import home from './modules/home'
import gis from './modules/gis'

import assets from './modules/assets'

import map from './modules/map'

import mapTools from './modules/map-tools'

import userSelector from './modules/user-selector'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    setting,
    auth,
    home,
    gis,
    assets,
    map,
    mapTools,
    userSelector,
  }
})