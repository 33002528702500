import Vue from "vue";
import App from "./App.vue";

import "./css/index.less";
import router from "./router";
import store from "./store";

import Card from "./components/card.vue";
import Money from "./components/money.vue";
import Upload from "./components/upload/index.vue";

import Num from "./components/num.vue";

import {
  Radio,
  ConfigProvider,
  Checkbox,
  Select,
  Modal as AModal,
  Slider,
  Button,
  Row,
  Col,
  Icon,
  Tabs,
  DatePicker,
  message,
  Descriptions,
  Form,
  FormModel,
  Table,
  Result,
  Input,
  Space,
  InputNumber,
  Cascader,
  Badge,
  Dropdown,
  Menu,
  Divider,
  Tag,
  Switch,
  Empty,
  Alert,
  AutoComplete,
  Pagination,
  Popconfirm
} from "ant-design-vue";

import Title from "./components/title";

import Modal from "./components/modal";

import DataDictFinder from "./components/data-dict-finder.vue";

import { selectFilterOption, ratioFormatter } from "@/utils/antdv";
import { print, download } from "@/utils/download";
import { getFileName } from "./utils/file";

Vue.component("Modal", Modal);
Vue.component("DataDictFinder", DataDictFinder);

Vue.use(Radio)
  .use(ConfigProvider)
  .use(Checkbox)
  .use(Slider)
  .use(Button)
  .use(Row)
  .use(Col)
  .use(Select)
  .use(Icon)
  .use(Tabs)
  .use(DatePicker)
  .use(FormModel)
  .use(Table)
  .use(Result)
  .use(Input)
  .use(Space)
  .use(Form)

  .use(AModal)
  .use(InputNumber)
  .use(Cascader)
  .use(Badge)
  .use(Dropdown)
  .use(Menu)
  .use(Divider)
  .use(Descriptions)
  .use(Tag)
  .use(Switch)
  .use(Empty)
  .use(Alert)
  .use(AutoComplete)
  .use(Pagination)
  .use(Popconfirm)

Vue.component("Title", Title);

Vue.component("Card", Card);
Vue.component("Money", Money);
Vue.component("Upload", Upload);
Vue.component("Num", Num);

message.config({
  maxCount: 1,
});

Vue.prototype.$message = message;
Vue.prototype.$confirm = AModal.confirm;
Vue.prototype.$error = AModal.error;
Vue.prototype.$success = AModal.success;

Vue.prototype.$selectFilterOption = selectFilterOption;
Vue.prototype.$ratioFormatter = ratioFormatter;
Vue.prototype.$getFileName = getFileName;

Vue.prototype.$print = print;
Vue.prototype.$download = download;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
