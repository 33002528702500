import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {

  // console.log('to', to)
  // console.log('from', from)

  const token = window.localStorage.getItem('token')

  // 未登录
  if (!token) {
    // 如果去的不是登录页，那就强制去登录页
    if (to.path !== '/login') {
      next({
        path: '/login'
      })
    } else {
      // 如果用户直接去了登录页，直接过去
      next();
    }
  } else {
    next();
  }

  // if (to.path !== '/login' && !token) {
  //   next({
  //     path: '/login',
  //   })
  // } else {
  //   next();
  // }
  // const menuList = window.localStorage.getItem("menuList");
  // if (menuList) {
  //   const menus = JSON.parse(menuList);
  //   const res = menus.find(item => item.path === to.path)
  //   if (res) {
  //     next();
  //   } else {
  //     next('/403')
  //   }
  // } else {
  //   console.log("local storage don't has menu list")
  //   next('/403')
  // }

})




export default router
