<template>
  <span>{{ value }}</span>
</template>

<script>
export default {
  props: {
    money: {},
  },
  computed: {
    value() {
      if (typeof this.money === "number") {
        return this.money.toLocaleString();
      } else {
        return this.money;
      }
    },
  },
};
</script>

<style lang="less" scoped>
span {
  color: var(--theme-color);
}
</style>