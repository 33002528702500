export default [

  {
    path: "/login",
    component: () => import("@/views/login"),
  },
  {
    path: "/data-screen",
    component: () => import("@/views/data-screen"),
  },
  {
    path: "/",
    component: () => import("@/layout"),
    children: [
      {
        path: "",
        component: () => import("@/views/home"),
      },
      {
        path: "open-map",
        component: () => import("@/views/open-map"),
      },
      {
        path: "gis",
        component: () => import("@/views/gis"),
      },
      {
        path: "manage/task",
        component: () => import("@/views/task/list"),
      },
      {
        path: "manage/task-config",
        component: () => import("@/views/task/temp"),
      },
      {
        path: "manage/map",
        component: () => import("@/views/task/map"),
      },
      {
        path: "manage/task-review",
        component: () => import("@/views/task/list/review"),
      },
      {
        path: "manage/task-remarks",
        component: () => import("@/views/task/remarks"),
      },
      {
        path: "manage/pipe-check",
        component: () => import("@/views/pipe-check"),
      },
      {
        path: "manage/pipe-check/task-editor",
        component: () => import("@/views/pipe-check/components/TaskEditor"),
      },

      {
        path: "manage/pipe-check/result-editor",
        component: () => import("@/views/pipe-check/components/ResultEditor"),
      },



      {
        path: "curing",
        component: () => import("@/views/curing"),
      },
      {
        path: "analysis",
        component: () => import("@/views/analysis"),
      },
      {
        path: "topic/schedule",
        component: () => import("@/views/topic/schedule"),
      },
      {
        path: "topic/water-quality",
        component: () => import("@/views/topic/water-quality"),
      },
      {
        path: "topic/water-level",
        component: () => import("@/views/topic/water-level"),
      },
      {
        path: "topic/maintenance",
        component: () => import("@/views/topic/maintenance"),
      },

      {
        // 水动力模型
        path: 'topic/model',
        component: () => import('@/views/model/map.vue')
      },

      {
        // 2924-1-31 张弘 增加污染物溯源
        path: 'topic/source',
        component: () => import("@/views/source/index.vue")
      },
      // 系统设置
      {
        path: "setting",
        redirect: "setting/log",
      },
      {
        path: "setting/alert-setting", // 2024-1-2增加setting-alarmConfig路径
        component: () => import("@/views/setting/alert-setting"),
      },
      {
        path: "setting/log",
        component: () => import("@/views/setting/log"),
      },

      {
        path: "setting/assets",
        component: () => import("@/views/assets"),
      },

      {
        path: "setting/permission",
        component: () => import("@/views/setting/permission"),
      },

      {
        path: "setting/menu",
        component: () => import("@/views/setting/menu"),
      },
      {
        path: "setting/menu/add",
        component: () => import("@/views/setting/menu/add"),
      },
      {
        path: "setting/menu/edit",
        component: () => import("@/views/setting/menu/edit"),
      },
      {
        path: "setting/role",
        component: () => import("@/views/setting/role"),
      },
      {
        path: "setting/role/add",
        component: () => import("@/views/setting/role/add"),
      },
      {
        path: "setting/role/edit",
        component: () => import("@/views/setting/role/edit"),
      },
      {
        path: "setting/user",
        component: () => import("@/views/setting/user"),
      },
      {
        path: "setting/user/add",
        component: () => import("@/views/setting/user/add"),
      },
      {
        path: "setting/user/edit",
        component: () => import("@/views/setting/user/edit"),
      },
      {
        path: "setting/data-dict",
        component: () => import("@/views/setting/data-dict"),
      },
      {
        path: "setting/data-dict/add",
        component: () => import("@/views/setting/data-dict/add"),
      },
      {
        path: "setting/data-dict/edit",
        component: () => import("@/views/setting/data-dict/edit"),
      },

      {
        path: "setting/map-label",
        component: () => import("@/views/setting/map-label"),
      },

      {
        path: "setting/app-version",
        component: () => import("@/views/setting/app-version"),
      },
      {
        path: "setting/app-version/add",
        component: () => import("@/views/setting/app-version/add"),
      },
      {
        path: "setting/app-version/edit",
        component: () => import("@/views/setting/app-version/edit"),
      },

      {
        path: "weather",
        component: () => import("@/views/weather"),
      },

      // 排水户
      {
        path: '/sewage-company/list',
        component: () => import('@/views/setting/sewage-company')
      },
      {
        path: '/sewage-company/license',
        component: () => import('@/views/setting/license')
      },
      {
        path: '/sewage-company/license/add',
        component: () => import('@/views/setting/license/add')
      },
      {
        path: '/sewage-company/license/detail',
        component: () => import('@/views/setting/license/detail')
      },


      // 企业资产管理
      {
        path: "/property",
        component: () => import("@/views/property"),
        children: [
          {
            path: "",
            redirect: "/property/list",
          },
          {
            path: "/property/list",
            component: () => import("@/views/property/list"),
          },
          {
            path: "/property/list/add",
            component: () => import("@/views/property/list/add"),
          },
          {
            path: "/property/list/edit",
            component: () => import("@/views/property/list/edit"),
          },


          {
            // 计量资产
            path: "/property/metering",
            component: () => import("@/views/property/metering"),
          },
          {
            // 闲置资产
            path: "/property/free",
            component: () => import("@/views/property/free"),
          },

          {
            // 价值统计
            path: "/property/analysis",
            component: () => import("@/views/property/analysis"),
          },

          // 采购
          {
            path: "/property/buy",
            component: () => import("@/views/property/buy"),
          },
          {
            path: "/property/buy/add",
            component: () => import("@/views/property/buy/add"),
          },
          {
            path: "/property/buy/edit",
            component: () => import("@/views/property/buy/edit"),
          },
          {
            path: "/property/buy/detail",
            component: () => import("@/views/property/buy/detail"),
          },
          {
            path: "/property/use",
            component: () => import("@/views/property/use"),
          },
          {
            path: "/property/use/add",
            component: () => import("@/views/property/use/add"),
          },
          {
            path: "/property/use/edit",
            component: () => import("@/views/property/use/edit"),
          },
          {
            path: "/property/use/detail",
            component: () => import("@/views/property/use/detail"),
          },

          {
            path: "/property/transfer",
            component: () => import("@/views/property/transfer"),
          },
          {
            path: "/property/transfer/add",
            component: () => import("@/views/property/transfer/add"),
          },
          {
            path: "/property/transfer/edit",
            component: () => import("@/views/property/transfer/edit"),
          },
          {
            path: "/property/transfer/detail",
            component: () => import("@/views/property/transfer/detail"),
          },

          {
            path: "/property/check",
            component: () => import("@/views/property/check"),
          },
          {
            path: "/property/check/add",
            component: () => import("@/views/property/check/add"),
          },
          {
            path: "/property/check/edit",
            component: () => import("@/views/property/check/edit"),
          },
          {
            path: "/property/check/detail",
            component: () => import("@/views/property/check/detail"),
          },

          {
            path: "/property/scrap",
            component: () => import("@/views/property/scrap"),
          },
          {
            path: "/property/scrap/add",
            component: () => import("@/views/property/scrap/add"),
          },
          {
            path: "/property/scrap/edit",
            component: () => import("@/views/property/scrap/edit"),
          },
          {
            path: "/property/scrap/detail",
            component: () => import("@/views/property/scrap/detail"),
          },
        ],
      },
      // 监督管理
      {
        path: "manage/supervise",
        component: () => import("@/views/supervise"),
      },
      {
        path: "manage/supervise/detail",
        component: () => import("@/views/supervise/detail"),
      },
      {
        path: "manage/personnel-assess",
        component: () => import("@/views/personnel-assess"),
      },
      {
        path: "manage/personnel-assess/add",
        component: () => import("@/views/personnel-assess/add"),
      },
      {
        path: "manage/personnel-assess/edit",
        component: () => import("@/views/personnel-assess/edit"),
      },
      {
        path: "manage/personnel-assess/assess",
        component: () => import("@/views/personnel-assess/assess"),
      },
      {
        path: "manage/log",
        component: () => import("@/views/task/log/index.vue"),
      },



      {
        // 消息中心
        path: "message",
        component: () => import("@/views/message"),
      },
      {
        path: "setting/message-config",
        component: () => import("@/views/setting/message-config/index.vue"),
      },



      {
        // 排污监测
        path: 'sewage-monitor',
        component: () => import('@/views/sewage-monitor')
      },




      {
        path: "*",
        component: () => import("@/views/404"),
      },
    ],
  },
];
