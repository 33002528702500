export default {

    namespaced: true,

    state() {
        return {
            searchVisible: false,
            searchLocation: {},

            isGettingDistance: false,
            isGettingArea: false,
            selectedLnglat: [],

            max: 0,
            min: 0,
            up: 0,
            down: 0,
            left: 0,
            right: 0,

            layer: '',

            area: {}, // 选择标签要描边或填充
        }
    },

    mutations: {
        setSearchVisible(state, payload) {
            state.searchVisible = payload;
        },
        setSearchLocation(state, payload) {
            state.searchLocation = payload;
        },
        setIsGettingDistance(state, payload) {
            state.isGettingArea = false;
            state.isGettingDistance = payload
        },
        setIsGettingArea(state, payload) {
            state.isGettingDistance = false;
            state.isGettingArea = payload;
        },
        setSelectedLnglat(state, payload) {
            state.selectedLnglat = payload
        },

        setMax(state, payload) {
            state.max = payload
        },
        setMin(state, payload) {
            state.min = payload
        },
        setUp(state, payload) {
            state.up = payload
        },
        setDown(state, payload) {
            state.down = payload
        },
        setLeft(state, payload) {
            state.left = payload
        },
        setRight(state, payload) {
            state.right = payload
        },
        setLayer(state, payload) {
            state.layer = payload
        },

        setArea(state, payload) {
            state.area = payload;
        }
    },
}