import request from '../request'

// 查询雨水排口坐标
export function fetchRainDrainLocs(data) {
    return request({
        url: '/model-analysis/pipe/rain/drain/locations',
        method:"post",
        data
    })
}

export function fetchList(params) {
    return request({
        url: '/model-analysis/pipe/drain/list',
        params
    })
}
export function fetchDetail(data) {
    return request({
        url: `/model-analysis/pipe/drain/query/${data.category}/${data.id}`,
        method:"post"
    })
}

export function add(data) {
    return request({
        url: `/model-analysis/pipe/drain/add`,
        method: 'post',
        data
    })
}

export function edit(data) {
    return request({
        url: '/model-analysis/pipe/drain/update',
        method: 'post',
        data
    })
}

export function del(data) {
    return request({
        url: '/model-analysis/pipe/drain/delete',
        method: 'post',
        data
    })
}