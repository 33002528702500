import request from '@/api/request'


// 获取污水收集区列表
export function fetchSewageScope(data) {
    return request({
        url: '/model-analysis/pipe/area',
        method: 'post',
        data
    })
}

export function fetchPipe(data) {
    return request({
        url: '/model-analysis/pipe/sewage/locations',
        method: 'post',
        data
    })
}

export function fetchPipeDetail(data) {
    return request({
        url: '/model-analysis/pipe/sewage/query',
        method: 'post',
        data
    })
}



// 污水井的位置
export function fetchWell(data) {
    return request({
        url: '/model-analysis/pipe/sewage/well/locations',
        method: 'post',
        data
    })
}

// 污水井详情
export function fetchWellDetail(data) {
    return request({
        url: '/model-analysis/pipe/sewage/well/query',
        method: 'post',
        data
    })
}


// 获取所有的设备，包括液位计等
export function fetchFacility(data) {
    return request({
        url: '/model-analysis/pipe/sewage/device/query',
        method: 'post',
        data
    })
}