import domtoimage from "dom-to-image";
import { saveAs } from "file-saver";

export function print(id) {
    const node = document.getElementById(id);
    console.log('dom id is error')
    if (!node) return;
    domtoimage
        .toPng(node)
        .then(function (dataUrl) {
            setIframe(dataUrl);
        })
        .catch(function (error) {
            console.error("oops, something went wrong!", error);
        });
}

export function setIframe(url) {
    const iframe = document.createElement("iframe");

    // iframe 不展示在页面
    iframe.style.height = 0;
    iframe.style.visibility = "hidden";
    iframe.style.width = 0;

    // 设置 iframe 内容
    iframe.setAttribute("srcdoc", "<html><body></body></html>");

    iframe.addEventListener("load", function () {
        // 克隆页面的图片元素
        const image = document.createElement("img");
        image.src = url;
        // const image = document.getElementById('image').cloneNode();
        image.style.width = "100%";

        image.addEventListener("load", function () {
            iframe.contentWindow.print();
        });

        // 把克隆的图片元素添加到 iframe 内
        const body = iframe.contentDocument.body;
        // body.style.textAlign = 'center';

        body.appendChild(image);
    });

    document.body.appendChild(iframe);
}

export function download(id) {
    const node = document.getElementById(id);
    if (!node) return;
    domtoimage
        .toPng(node)
        .then(function (dataUrl) {
            saveAs(dataUrl, "下载图片.png");
        })
        .catch(function (error) {
            console.error(" something went wrong!", error);
        });
}