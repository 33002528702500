import { fetchList } from "@/api/setting/user";

export default {

    namespaced: true,

    state() {
        return {
            userList: [],
        }
    },

    mutations: {
        setUserList(state, payload) {
            state.userList = payload
        },
    },

    actions: {
        getUserList({ commit }) {
            fetchList({
                pageNum: 1,
                pageSize: 100,
            }).then(res => {
                if (res && Array.isArray(res.list)) {
                    commit('setUserList', Object.freeze(res.list))
                }
            })
        },
    }
}